import { KpkApiAdvancedSearchCriteria, KpkApiSheetField } from "@keepeek/api-client";
import {
  buildAdvancedFilterList,
  BusinessFilter,
  BusinessFilterNature,
  createBasketBusinessFilter,
  createFullTextBusinessFilter,
  KpkFilterModifierType,
  KpkFilterType,
  LightBusinessFilter,
} from "@keepeek/commons";
import { getI18n } from "react-i18next";

/**
 * allows you to convert light business filters into business filters
 */
export function convertToBusinessFilter(
  lightBusinessFilters: LightBusinessFilter[],
  sheetFields: KpkApiSheetField[] | undefined,
  criterias: KpkApiAdvancedSearchCriteria[] | undefined,
): BusinessFilter[] {
  const ret: BusinessFilter[] = [];
  lightBusinessFilters.forEach((lbf) => {
    try {
      switch (lbf.n) {
        case BusinessFilterNature.FACET:
          ret.push({
            nature: lbf.n,
            filter: {
              id: lbf.f.i,
              label: lbf.f.l || "",
              type: KpkFilterType.RadioFacet,
              internalName: lbf.f.f,
              values: lbf.f.v.map((v) => {
                return {
                  id: v.i,
                  label: v.l || "",
                  title: v.t,
                  selected: v.s,
                };
              }),
              modifiers: null,
              notFoundReference: lbf.f.nfr,
            },
            hide: lbf.h,
          });
          break;
        case BusinessFilterNature.FACET_MULTI:
          ret.push({
            nature: lbf.n,
            filter: {
              id: lbf.f.i,
              label: lbf.f.l || "",
              type: KpkFilterType.Checkbox,
              internalName: lbf.f.f,
              values: lbf.f.v.map((v) => {
                return {
                  id: v.i,
                  label: v.l || "",
                  title: v.t,
                  selected: v.s,
                };
              }),
              modifiers: [
                {
                  selected: true,
                  type: lbf.f.m ?? KpkFilterModifierType.ContainsOne,
                },
              ],
              notFoundReference: lbf.f.nfr,
            },
            hide: lbf.h,
          });
          break;
        case BusinessFilterNature.FULL_TEXT:
          ret.push(
            createFullTextBusinessFilter(
              lbf.f.v[0].i,
              getI18n().t("business-filter.full-text.label"),
            ),
          );
          break;
        case BusinessFilterNature.BASKET:
          ret.push(createBasketBusinessFilter(lbf.f.v[0].i));
          break;
        case BusinessFilterNature.ADVANCED:
          const internalName = lbf.f.i;
          const lbfValues = lbf.f.v;
          const currentSelectedModifierType = lbf.f.m;
          const advancedFilter = buildAdvancedFilterList(internalName, sheetFields, criterias).find(
            (af) => af.filter.id === internalName,
          );
          if (advancedFilter && lbf.f.nfr) {
            advancedFilter.filter.notFoundReference = true;
          }
          if (!advancedFilter) {
            console.error(`can't find light business filter for internalName=${internalName}`);
            break;
          }
          // values => we take the values that were present in the light business filter
          advancedFilter.filter.values = lbfValues.map((v) => {
            return {
              id: v.i,
              label: v.l,
              title: v.t,
              selected: v.s,
              addQuotes: v.aq,
            };
          });
          // set current selected modifier
          if (advancedFilter.filter.modifiers) {
            advancedFilter.filter.modifiers = advancedFilter.filter.modifiers.map((m) => {
              return {
                ...m,
                selected: m.type === currentSelectedModifierType ? true : null,
              };
            });
          } else if (currentSelectedModifierType) {
            advancedFilter.filter.modifiers = [];
            advancedFilter.filter.modifiers.push({
              selected: true,
              type: currentSelectedModifierType,
            });
          }
          ret.push(advancedFilter);
          break;
        default:
          console.error(`light filter business of nature ${lbf.n} is not implemented`);
      }
    } catch (error) {
      console.error(`can't convert light business filter to business filter lbf=${lbf}`, error);
    }
  });

  return ret;
}
