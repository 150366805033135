import React, { FC } from "react";

import { FieldProps } from "@rjsf/utils";
import { JSONSchema7Definition } from "json-schema";
import { pick } from "lodash";

import { DisplayMode } from "../../../../models/configuration/components/kNewsWidget";

const CustomWidgetNews: FC<React.PropsWithChildren<FieldProps>> = function (props: FieldProps) {
  const { ObjectField } = props.registry.fields;

  const displayMode = props.formData.displayMode;

  const imageSchema = {
    ...props.schema,
    properties: pick(
      props.schema.properties,
      "id",
      "displayMode",
      "fixedHeight",
      "borderRadius",
      "backgroundTextEffect", // specific image
      "backgroundTextColor", // specific image
      "verticalTextAlign", // specific image
      "content",
      "image",
      "url",
      "chip",
      "marginTop",
      "marginBottom",
    ),
  };

  const columnSchema = {
    ...props.schema,
    properties: pick(
      props.schema.properties,
      "id",
      "displayMode",
      "fixedHeight",
      "backgroundColor", // specific column
      "borderColor", // specific column
      "borderRadius",
      "content",
      "image",
      "imagePosition", // specific column
      "imageWidthAdaptation", // specific column
      "url",
      "chip",
      "button", // specific column
      "marginTop",
      "marginBottom",
      "padding", // specific column
    ),
  };

  if (displayMode === DisplayMode.Image) {
    return (
      <ObjectField {...props} schema={imageSchema as { [key: string]: JSONSchema7Definition }} />
    );
  }
  return (
    <ObjectField {...props} schema={columnSchema as { [key: string]: JSONSchema7Definition }} />
  );
};

export default CustomWidgetNews;
