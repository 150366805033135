import { removeUndefinedDeep } from "@keepeek/commons";

import { CONFIGURATION_SECTION_PATH } from "../../../components/layout/adminMenu";
import { JsonConfig } from "../../../lib/commonPagePropsUtil";
import { getAdminConfigSectionData } from "../../../lib/config/customer-config-utils";
import logger from "../../../lib/logger-utils";
import {
  BackgroundTextEffect,
  DisplayMode,
  KNewsWidget,
  VerticalTextAlign,
} from "../../../models/configuration/components/kNewsWidget";
import { Type } from "../../../models/configuration/definitions/widget";

import { KNewsWidgetProps } from "./index";

export function getNewsWidgetConfiguration(
  customerSectionConfiguration: JsonConfig[],
  widgetId: string,
): KNewsWidgetProps | undefined {
  const newsWidgets = getAdminConfigSectionData<KNewsWidget[]>(
    customerSectionConfiguration,
    CONFIGURATION_SECTION_PATH.COMPONENTS_NEWS_WIDGET,
  );

  if (newsWidgets?.length === undefined || newsWidgets.length === 0) {
    logger.debug(`getNewsWidgetConfiguration - newsWidgets is empty or undefined`);
    return;
  }

  const newsWidget: KNewsWidget | undefined = newsWidgets?.find((m) => m.id === widgetId);
  if (!newsWidget) {
    logger.debug(`getNewsWidgetConfiguration - can't find news widget id ${widgetId}`);
    return;
  }

  // For SSR, do not return any object undefined cause it can't be used in JSON
  const config: KNewsWidgetProps = {
    widgetId: newsWidget?.id,
    fixedHeight: newsWidget?.fixedHeight,
    backgroundColor: newsWidget?.backgroundColor,
    url: newsWidget.url,
    borderColor: newsWidget?.borderColor,
    borderRadius: newsWidget?.borderRadius,
    content: newsWidget?.content,
    button: newsWidget?.button,
    chip: newsWidget?.chip,
    image: newsWidget?.image,
    imagePosition: newsWidget?.imagePosition,
    imageWidthAdaptation: newsWidget?.imageWidthAdaptation,
    type: Type.News,
    marginTop: newsWidget?.marginTop,
    marginBottom: newsWidget?.marginBottom,
    displayMode: newsWidget?.displayMode ?? DisplayMode.Article,
    backgroundTextEffect: newsWidget?.backgroundTextEffect ?? BackgroundTextEffect.NoBackground,
    backgroundTextColor: newsWidget?.backgroundTextColor ?? "",
    verticalTextAlign: newsWidget?.verticalTextAlign ?? VerticalTextAlign.Top,
    padding: newsWidget?.padding,
  };
  // Include only when defined
  return removeUndefinedDeep(config);
}
