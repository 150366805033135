import React, { FC } from "react";

import { ComponentOverrideKey, ComponentOverrideProps } from "@keepeek/refront-components";
import Head from "next/head";
import { useRouter } from "next/router";

import { getCustomerOverrideComponent } from "../../lib/overrides";

export const ServerSide: FC<React.PropsWithChildren<{}>> = function () {
  const router = useRouter();
  const currentLang = router.locale;
  const CustomSSRHead = getCustomerOverrideComponent<
    ComponentOverrideProps[ComponentOverrideKey.SSRHead]
  >(ComponentOverrideKey.SSRHead, undefined);

  if (!CustomSSRHead) {
    // because of a recoil memory leak when using selector / selectorFamily we are forced to disable the rendering of components in SSR in order not to explode the server memory ...
    // even version 0.4.0 of Recoil which allows you to manage the cache does not help...
    // see RF-851 for more detail
    return null;
  }

  // Do not render other thing than the custom SSR head because of a recoil memory leak (see comment above):
  return (
    <>
      {CustomSSRHead && (
        <Head>
          <CustomSSRHead locale={currentLang} />
        </Head>
      )}
    </>
  );
};
